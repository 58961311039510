/* global pbContactForm */

window.addEventListener('load', function () {
	const modal = document.getElementById("grading-issues-modal");
	const btn = document.getElementById("grading-issues-btn");
	const span = document.getElementsByClassName("close")[0];

	btn.onclick = function () {
		modal.style.display = 'block';
	}

	span.onclick = function () {
		modal.style.display = 'none';
	}

	window.onclick = function (event) {
		if (event.target === modal) {
			document.getElementById('grading-issues-form').style.display = 'block';
			document.getElementById('contact-form-success-message').style.display = 'none';
			document.getElementById('contact-form-error-message').style.display = 'none';
			modal.style.display = 'none';
		}
	}

	document.getElementById('grading-issues-form').onsubmit = function (event) {
		event.preventDefault();

		document.getElementById('contact-form-submit').disabled = true;

		const data = {
			name: document.getElementById('student-name').value,
			email: document.getElementById('student-email').value,
			chapter_url: document.getElementById('assignment-link').value,
			attempt_date: document.getElementById('attempt-date').value,
			activity_name: document.getElementById('activity-name').value,
			course_id: document.getElementById('course-id').value,
			course_name: document.getElementById('course-name').value,
			platform_id: document.getElementById('platform-id').value,
			platform_name: document.getElementById('platform-name').value,
			platform_lms: document.getElementById('platform-lms').value,
			os_browser: document.getElementById('os-browser').value,
			comments: document.getElementById('comments').value,
			nonce: document.getElementById('_wpnonce').value,
		};

		fetch(pbContactForm.ajaxUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: new URLSearchParams(data),
		}).then(response => {
			document.getElementById('grading-issues-form').style.display = 'none';
			const messageClass = response.ok ? 'contact-form-success-message' : 'contact-form-error-message';
			document.getElementById(messageClass).style.display = 'block';
		}).catch(error => {
			document.getElementById('grading-issues-form').style.display = 'none';
			document.getElementById('contact-form-submit').disabled = false;
			document.getElementById('contact-form-error-message').style.display = 'block';
		});

	}
});
